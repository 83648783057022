#contactPage {
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width:60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPageTitle {
    font-size: 3rem;
    margin-bottom: 2.5rem;

}

.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}
.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.submitBtn {
    background-color: white;
    color: black;
    border: none;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
}

.links {
    display: flex;
    flex-wrap: wrap; 
}

.link {
    object-fit: cover;
    margin: 0 0.75rem;

}

#linkedin {
    height: 90px;
    width: 90px;
}

@media screen and (max-width:600px) {
    #linkedin {
        height: 50px;
        width: 50px;
    }
}