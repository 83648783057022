#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

h2 {
    color: #e493cf;
}

.skillTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.skillDesc {
    font-weight: 200;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;
    font-style: oblique;
}

.skillBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background-color: rgb(50, 50, 50);
}

#basedatos {
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 1.2rem;
    
}

.skillBarImg {
    object-fit: cover;
    margin-right: 2rem;
}

.skillBarText>p {
    font-size: 1rem;
    font-weight: bold;
}

.skillBarText {
    font-weight: 700;
}

@media screen and (max-width: 720px){
    .skillBarText>p {
        font-size: 2vw;
    }    
}

@media screen and (max-width: 480px){
    .skillBarText>p {
        font-size: 3vw;
    }
    .skillBarImg {
        height: 2.25rem;
        width: 2.25rem;
    }
    #basedatos{
        height: 3rem;
        width: 3rem;
    }
    .skillBarText>h2 {
        font-size: 5vw;
    }
}